<template>
  <main class="h-full flex-grow overflow-y-auto hiddenScrollbar pb-5">
    <Breadcrumbs />
    <!-- content area -->
    <div class="container relative p-8 mx-auto h-auto">
      <RouterView />
    </div>
  </main>
</template>
<script setup lang="ts">
import Breadcrumbs from "./Breadcrumbs.vue";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
</script>
