<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="17"
    viewBox="0 0 13 17"
  >
    <g id="Group_9245" data-name="Group 9245" transform="translate(-1119 -719)">
      <path
        id="Polygon_52"
        data-name="Polygon 52"
        d="M6.134.395a.5.5,0,0,1,.733,0L12.22,6.16a.5.5,0,0,1-.366.84H1.147A.5.5,0,0,1,.78,6.16Z"
        transform="translate(1132 736) rotate(180)"
        fill="#005caa"
      />
      <path
        id="Polygon_53"
        data-name="Polygon 53"
        d="M6.134.395a.5.5,0,0,1,.733,0L12.22,6.16a.5.5,0,0,1-.366.84H1.147A.5.5,0,0,1,.78,6.16Z"
        transform="translate(1119 719)"
        fill="#005caa"
      />
    </g>
  </svg>
</template>
