import { useHttpClient } from "@/core/network/httpClient";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import {
  getAuth,
  signInWithEmailAndPassword,
  type UserCredential,
  getIdToken,
  signOut,
  getIdTokenResult,
} from "firebase/auth";
import { nextTick } from "vue";
import { AxiosProgressEvent } from "axios";

interface PermissionList {
  general: any[];
  settings: any[];
}

export const useAuth = defineStore("auth", () => {
  const auth = getAuth();
  const api = useHttpClient();
  //
  const user = ref<UserData>();
  const is_loading = ref(false);
  const permissionsList = ref<PermissionList>({ general: [], settings: [] });

  // loading progress status
  const progressVal = ref(0);

  const onProgress = (event: AxiosProgressEvent) => {
    const contentLength =
      event.event.srcElement.getResponseHeader("content-length");
    console.log("contentLength", contentLength);

    console.log(event);

    const percentCompleted = Math.round(
      (event.loaded * 100) / (event?.total || 1)
    );
    console.log(percentCompleted);
    progressVal.value = percentCompleted;
  };

  const login = async (credentials: LoginCredentials) => {
    return new Promise((resolve, reject) => {
      is_loading.value = true;
      signInWithEmailAndPassword(auth, credentials.email, credentials.password)
        .then(async (userCredential: UserCredential) => {
          const token = await getIdToken(userCredential.user);
          const additionalUserInfo = await getIdTokenResult(
            userCredential.user
          );
          const claims: any = additionalUserInfo.claims;
          user.value = {
            permissions: claims.permissions,
            name: claims.name,
            email: claims.email,
            id: claims.user_id,
            phone: claims?.phone || "",
            photoUrl: claims?.photoUrl || "",
            createdAt: new Date(claims?.createdAt).toLocaleDateString(),
            role: claims?.role || "",
          };
          localStorage.setItem("token", token);

          nextTick(() => {
            resolve({
              ...user.value,
              user_type: claims.user_type,
              email_verified: claims.email_verified,
              verified: claims.verified,
            });
          });
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          is_loading.value = false;
        });
    });
  };

  const send_opt = async () => {
    try {
      const { data } = await api.get("/v1.0/management/Login");
      return data;
    } catch (e) {
      throw e;
    }
  };

  const getPermissions = async () => {
    try {
      const { data } = await api.get("/v1.0/management/Lists/permissions");
      permissionsList.value = data.content;
      return data;
    } catch (e) {
      throw e;
    }
  };

  const set_new_password = (props: SetNewPasswordCredential) => {
    return new Promise((resolve, reject) => {
      is_loading.value = true;
      api
        .post("/v1.0/management/Login", props)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => (is_loading.value = false));
    });
  };

  const getProfile = async () => {
    is_loading.value = true;
    try {
      const { data } = await api.get("/v1.0/management/Profile");
      user.value = {
        ...data.content,
        createdAt: new Date(data.content.createdAt).toLocaleTimeString(),
      };
      return data;
    } catch (e) {
      return null;
    } finally {
      is_loading.value = false;
    }
  };

  const logout = async () => {
    is_loading.value = true;
    await signOut(auth)
      .then(() => {
        localStorage.removeItem("token");
        location.href = "/auth/login";
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        is_loading.value = false;
      });
  };

  return {
    user,
    login,
    logout,
    getProfile,
    is_loading,
    set_new_password,
    send_opt,
    getPermissions,
    permissionsList,
    progressVal,
  } as const;
});
