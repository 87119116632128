<template>
  <div v-if="message" v-bind="$attrs">
    <div class="alert" :class="alertType">
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  type: string;
  message: string;
}>();

const alertType = computed(() => {
  return `alert-${props.type}`;
});
</script>
