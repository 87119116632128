import { defineStore } from "pinia";
import { computed, ref, reactive } from "vue";
import { useHttpClient } from "@/core/network/httpClient";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { useModal } from "@/core/components/utils/modals/store/modalStore";
import { ModalState } from "../../../core/components/utils/modals/store/modalStore";

export enum UploadType {
  None = 0,
  UserProfile = 1,
  AdminProfile = 2,
  Grade = 3,
  Subject = 4,
  Stage = 5,
  Difficulty = 6,
  Badge = 7,
  Question = 8,
  BugReport = 10,
  ExportFile = 11,
  Suggestion = 12,
  Files = 13,
}

export const useImages = defineStore("ImagesStore", () => {
  const api = useHttpClient();
  const { showSuccessModal, showDeleteModal } = useModal();
  const { t } = useI18n();

  const API_URL = "/v1.0/management/Images";

  const state = reactive<{ data: Images[]; selectedData: string[] }>({
    data: [],
    selectedData: [],
  });

  const toast = useToast();
  const loading = ref<boolean>(false);

  const uploadTypesList = computed(() => {
    return [
      { label: t("user-profile"), value: UploadType.UserProfile },
      { label: t("admin-profile"), value: UploadType.AdminProfile },
      { label: t("grade"), value: UploadType.Grade },
      { label: t("subject"), value: UploadType.Subject },
      { label: t("stage"), value: UploadType.Stage },
      { label: t("difficulty"), value: UploadType.Difficulty },
      { label: t("badge"), value: UploadType.Badge },
      { label: t("question"), value: UploadType.Question },
      { label: t("bug-report"), value: UploadType.BugReport },
      { label: t("export-file"), value: UploadType.ExportFile },
      { label: t("suggestion"), value: UploadType.Suggestion },
      { label: t("files"), value: UploadType.Files },
      { label: t("none"), value: UploadType.None },
    ];
  });

  // fetch data
  const fetchData = async (type: UploadType | null = null) => {
    try {
      const { data: fetchedData } = await api.get(`${API_URL}`, {
        params: {
          type: type ?? null,
        },
      });
      state.data = fetchedData.content;
      return state;
    } catch (e: any) {
      throw e;
    }
  };

  const create = async (
    imageFile: File,
    uploadType: UploadType = UploadType.None
  ) => {
    try {
      const formData = new FormData();
      formData.append("photo", imageFile!);
      formData.append("type", uploadType!.toString());
      const res = await api.post(API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(res.data.message);
      return res.data.content;
    } catch (e: any) {
      throw e;
    } finally {
      loading.value = false;
    }
  };

  const toggleChecked = (id: string) => {
    if (state.selectedData.includes(id)) {
      state.selectedData = state.selectedData.filter(
        (item_id) => item_id != id
      );
    } else {
      state.selectedData.push(id);
    }
  };

  const deleteImages = async () => {
    const { reason, modalState, adminPassword } = await showDeleteModal({
      title: t("delete-image"),
      message: t("are-you-sure-you-want-to-delete-this-selected-images"),
    });
    if (modalState === ModalState.Canceled) return;
    loading.value = true;
    Promise.all(
      state.selectedData.map(async (id) => {
        await api.request({
          url: `${API_URL}/${id}`,
          method: "delete",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            reason,
            adminPassword,
          },
        });
      })
    )
      .then(async () => {
        state.selectedData = [];
        await fetchData();
        showSuccessModal(t("delete-image"), t("image-deleted-successfully"));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const deleteImage = async (id: string) => {
    const { reason, modalState, adminPassword } = await showDeleteModal({
      title: t("delete-image"),
      message: t("are-you-sure-you-want-to-delete-this-image"),
    });
    if (modalState === ModalState.Canceled) return;
    loading.value = true;
    try {
      await api.request({
        url: `${API_URL}/${id}`,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          reason,
          adminPassword,
        },
      });
      await fetchData();
      showSuccessModal(t("delete-image"), t("image-deleted-successfully"));
    } catch (e: any) {
      throw e;
    } finally {
      loading.value = false;
    }
  };

  const getUploadType = (type: UploadType) => {
    switch (type) {
      case UploadType.UserProfile:
        return "user-profile";
      case UploadType.AdminProfile:
        return "admin-profile";
      case UploadType.Grade:
        return "grade";
      case UploadType.Subject:
        return "subject";
      case UploadType.Stage:
        return "stage";
      case UploadType.Difficulty:
        return "difficulty";
      case UploadType.Badge:
        return "badge";
      case UploadType.Question:
        return "question";
      case UploadType.BugReport:
        return "bug-report";
      case UploadType.ExportFile:
        return "export-file";
      case UploadType.Suggestion:
        return "suggestion";
      case UploadType.Files:
        return "files";
      default:
        return "";
    }
  };

  return {
    state,
    data: computed(() => state.data),
    fetchData,
    loading: computed(() => loading.value),
    API_URL,
    create,
    getUploadType,
    deleteImage,
    uploadTypesList,
    toggleChecked,
    deleteImages,
  };
});
