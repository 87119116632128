<template>
  <div
    tabindex="0"
    v-bind="$attrs"
    @focus="isOpened = true"
    @click="isOpened = true"
    @focusout="isOpened = false"
    id="selectInput"
    class="bg-secondary px-4 py-3 rounded-lg primaryShadow cursor-pointer relative w-80 select-none"
    :class="disabled ? 'opacity-90 cursor-not-allowed' : ''"
  >
    <div class="flex justify-between items-center gap-2 select-none">
      <div class="truncate select-none">{{ placeholder }}</div>
      <div
        class="flex items-center justify-between gap-5 text-sm select-none truncate"
      >
        <span>{{ value || "N/A" }}</span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="7"
            viewBox="0 0 13 7"
          >
            <path
              id="Polygon_48"
              data-name="Polygon 48"
              d="M6.134.395a.5.5,0,0,1,.733,0L12.22,6.16a.5.5,0,0,1-.366.84H1.147A.5.5,0,0,1,.78,6.16Z"
              transform="translate(13 7) rotate(180)"
              fill="#005caa"
            />
          </svg>
        </span>
      </div>
    </div>

    <ul
      v-if="isOpened && !disabled"
      @focusout="isOpened = !isOpened"
      class="absolute top-14 rounded-lg select-none bg-secondary w-72 mx-auto flex flex-col gap-2 max-h-56 customScrollBar overflow-y-scroll"
    >
      <li
        v-for="(item, index) in options"
        :key="index"
        :class="
          item.label == value ? 'bg-tertiary text-primary font-medium' : ''
        "
        class="cursor-pointer select-none px-4 py-2 hover:bg-primary hover:text-white"
        @click="onSelect(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { nextTick } from "vue";
import { onUnmounted } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";

const isOpened = ref<boolean>(false);

defineProps<{
  placeholder: string;
  value: string;
  disabled?: boolean;
  options: any[];
}>();

const emit = defineEmits(["change"]);

const onSelect = (item: any) => {
  emit("change", item);
  isOpened.value = false;
};

// listen to escape key to close the modal
const handleEscape = (e: KeyboardEvent) => {
  if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
    isOpened.value = false;
  }
};

onMounted(() => {
  nextTick(() => {
    // add event listeners
    document.addEventListener("keydown", handleEscape);
  });
});

onUnmounted(() => {
  // remove event listeners
  document.removeEventListener("keydown", handleEscape);
});
</script>
