<template>
  <nav
    id="sidebar"
    :class="
      is_active ? 'rtl:right-0 ltr:left-0' : 'rtl:-right-3/4 ltr:-left-3/4'
    "
    class="absolute z-50 w-3/4 h-full overflow-scroll bg-white shadow-md lg:static lg:w-full lg:col-span-2 hiddenScrollbar"
  >
    <div class="flex h-full ">
      <section id="main-logo" class="h-full bg-white shadow-md">
        <router-link to="/">
          <Image
            src="/logo.svg"
            class="object-cover h-[42px] w-[42px] mx-auto m-3"
            alt="tamayos"
            loading="lazy"
          />
        </router-link>
        <ul class="flex flex-col justify-center gap-5 mt-12 bg-white">
          <li
            class="block w-full"
            v-for="(link, index) in navLinks"
            :key="index"
          >
            <button
              @click="() => setSubRoutes(link.route_name)"
              :name="link.route_name"
              class="flex items-center justify-center w-full p-3"
              :class="[
                selectedMenu?.title == link.title
                  ? 'bg-primary text-black shadow-md'
                  : '',
                'rtl:rounded-l-xl ltr:rounded-r-xl',
              ]"
            >
              <div :name="link.title">
                <template v-if="link.icon">
                  <component :is="link.icon" />
                </template>
              </div>
            </button>
          </li>
        </ul>
      </section>
      <section class="h-full w-full">
        <header class="flex items-center justify-between px-2 my-3">
          <div class="flex items-center justify-center gap-3">
            <h6 class="text-lg font-normal lg:text-2xl">
              {{ $t("faz") }}
            </h6>
            <span class="font-light text-[10px] whitespace-nowrap">{{
              $t("control-panel")
            }}</span>
          </div>
          <button class="lg:hidden" @click="toggleSidebar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
            >
              <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
            </svg>
          </button>
        </header>
        <ul class="flex flex-col justify-center gap-2 mt-12">
          <li
            v-for="(link, index) in selectedMenu?.children || []"
            :key="index"
          >
            <RouterLink
              :to="{ name: link.route_name }"
              class="flex items-center justify-start px-5 py-3 text-sm whitespace-pre-wrap"
              :class="
                router.currentRoute.value.name == link.route_name
                  ? 'text-primary bg-tertiary font-bold'
                  : 'text-primaryDark'
              "
            >
              <span>{{ link.title }}</span>
            </RouterLink>
          </li>
        </ul>
      </section>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { ref, onMounted, type Ref } from "vue";
import navLinks from "@/core/constant/navLinks";
import { RouteRecordRaw, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import useHasRole from "@/core/hooks/useHasRole";
import { routes } from "@/router";

defineProps<{
  is_active: boolean;
}>();

const emit = defineEmits(["toggleSidebar"]);
const router = useRouter();
const { has } = useHasRole();

const selectedMenu = ref<NavLink>();

const toggleSidebar = () => {
  emit("toggleSidebar");
};

onMounted(() => {
  setInitialRoute();
});

const getNavLink = (route_name: string) => {
  const navLink = navLinks.find((link) => link.route_name === route_name);
  const navLinkChild = navLinks.find((link) =>
    link.children?.find((child) => child.route_name === route_name)
  );
  if (!navLinkChild) {
    return navLink as NavLink;
  }

  return navLinkChild as NavLink;
};

const getParent = () => {
  let parentRoute = null;
  const start = (routerList: RouteRecordRaw[] = routes) => {
    routerList.forEach((route) => {
      if (route.children) {
        route.children?.forEach((child) => {
          if (child.name === router.currentRoute.value.name) {
            parentRoute = route;
          } else {
            start(route.children);
          }
        });
      }
    });
  };

  start();

  return parentRoute as RouteRecordRaw | null;
};

const setMenu = (route: RouteRecordRaw) => {
  const currentRoute = router.currentRoute.value.name;
  if (route.name === currentRoute) {
    const navLink = getNavLink(route.name as string);
    if (navLink) {
      selectedMenu.value = navLink;
    } else {
      const parentRoute = getParent() as RouteRecordRaw;
      const navLink = getNavLink(parentRoute?.name as string);
      selectedMenu.value = navLink;
      if (!navLink) {
        selectedMenu.value = navLinks[0];
      }
    }
  } else if (route.children) {
    route.children?.forEach((child) => {
      setMenu(child);
    });
  }
};

const setInitialRoute = () => {
  routes.forEach((route) => {
    setMenu(route);
  });
};

const setSubRoutes = (route_name: string) => {
  // when click on the large icon in the sidebar it will set the first sub route as the current route
  selectedMenu.value = navLinks.find(
    (link) => link.route_name === route_name
  ) as NavLink;
  if (
    selectedMenu.value?.children &&
    selectedMenu.value?.children?.length > 0
  ) {
    router.push({ name: selectedMenu.value.children[0].route_name });
  }

  document.querySelector("#sidebar")?.scrollTo({ top: 0, behavior: "smooth" });
};
</script>
