<template>
  <button
    @click="handleClick"
    :disabled="disabled"
    v-bind="$attrs"
    class="px-4 py-3 rounded-lg bg-warning/25 text-warning disabled:text-white"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Icon_metro-blocked"
        data-name="Icon metro-blocked"
        d="M19.642,4.857A10,10,0,0,0,5.5,19,10,10,0,0,0,19.642,4.857Zm.429,7.071a7.459,7.459,0,0,1-1.389,4.343L8.227,5.817a7.5,7.5,0,0,1,11.843,6.111Zm-15,0A7.459,7.459,0,0,1,6.46,7.585L16.914,18.039A7.5,7.5,0,0,1,5.071,11.928Z"
        transform="translate(-2.571 -1.928)"
        fill="currentColor"
      />
    </svg>
  </button>
</template>
<script setup lang="ts">
defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["click"]);

function handleClick() {
  emit("click");
}
</script>
