<template>
  <button
    :id="title"
    :aria-placeholder="title"
    :type="type"
    @click="onClick"
    :class="` button-primary  ${className}`"
    :disabled="disabled"
  >
    <svg
      v-if="loading"
      xmlns="http://www.w3.org/2000/svg"
      class="animate-spin spinner-border spinner-border-sm text-center"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style="fill: currentColor; transform: ; msfilter: "
    >
      <path
        d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
      ></path>
    </svg>

    <span v-else class="flex items-center justify-center gap-2">
      <span>
        {{ title }}
      </span>
    </span>
  </button>
</template>
<script lang="ts" setup>
import { Component } from "vue";

defineProps<{
  disabled?: boolean;
  loading?: boolean;
  type: "button" | "submit" | "reset";
  title: string;
  className?: string;
  icon?: Component;
}>();

const emit = defineEmits(["click"]);

function onClick() {
  emit("click");
}
</script>
