import { t } from '@/core/i18n'

const API_URL = '/v1.0/management/Difficulties'

const MODULE_TITLE = t('app-game-controls')

const NAME = 'difficulty'

const settings: AppContainerSettings = {
  hasView: false,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  notSelectable: true,
  rowsOptions: {
    isCenter: true,
    hasView: false,
    hasBlock: false,
    hasDelete: false,
    hasEdit: true,
  },
}

const tableHeader: LabelVal[] = [
  {
    label: 'id',
    value: 'id',
  },
  {
    label: t('difficulty-design'),
    value: 'DifficultyDesign',
  },
  {
    label: t('difficulty-name-ar-en'),
    value: 'DifficultyNameArEn',
  },
  {
    label: t('active-status'),
    value: 'ActiveStatus',
  },
  {
    label: t('reward'),
    value: 'Reward',
  },
  {
    label: t('no-of-questions'),
    value: 'NoOfQuestions',
  },
  {
    label: t('time-per-question'),
    value: 'Time Per question',
  },
  {
    label: t('action'),
    value: 'Action',
  },
]

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
}
