<template>
  <div
    v-bind="$attrs"
    class="relative overflow-x-auto overflow-y-hidden customScrollBar rounded-t-lg"
    :class="class"
  >
    <table class="w-full text-justify bg-white">
      <thead>
        <tr class="p-4 bg-primary/20">
          <th
            class="px-4 py-3 text-xs font-semibold whitespace-nowrap text-dark800"
            v-for="header in headers"
            :class="headerCenter ? '!text-center' : 'text-justify'"
            :key="header.value"
          >
            <div
              class="flex items-center gap-2 align-middle"
              :class="headerCenter ? 'justify-center' : ''"
            >
              <button
                v-if="
                  header.value != 'id' &&
                  !header.value.includes('Action') &&
                  !noSorting
                "
                @click="() => setQueryParams(header.value)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.335"
                  height="12.667"
                  viewBox="0 0 9.335 12.667"
                >
                  <g
                    id="Group_8602"
                    data-name="Group 8602"
                    transform="translate(-419 -296.086)"
                    opacity="0.46"
                  >
                    <path
                      id="arrow_drop_down_FILL0_wght200_GRAD0_opsz48"
                      d="M21.067,25.667,16.4,21h9.334Z"
                      transform="translate(402.6 283.086)"
                    />
                    <path
                      id="arrow_drop_down_FILL0_wght200_GRAD0_opsz48-2"
                      data-name="arrow_drop_down_FILL0_wght200_GRAD0_opsz48"
                      d="M21.067,25.667,16.4,21h9.334Z"
                      transform="translate(444.734 321.753) rotate(180)"
                    />
                  </g>
                </svg>
              </button>
              <check-box
                v-else-if="header.value == 'id' && !notSelectable"
                @change="$emit('selectAll')"
                :checked="!!isAllSelected"
              />
              <span class="align-middle">
                {{ header.label }}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot name="body"></slot>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";

const router = useRouter();

defineProps<{
  headers: LabelVal[];
  isAllSelected?: boolean;
  class?: string;
  headerCenter?: boolean;
  noSorting?: boolean;
  notSelectable?: boolean;
}>();

defineEmits(["selectAll"]);

const setQueryParams = (prop: string) => {
  const header_key = (prop == "#" ? "id" : prop).toLowerCase();
  const prev_query = router.currentRoute.value.query;
  const query = Object.assign({}, prev_query);
  query[header_key] = query[header_key] === "asc" ? "desc" : "asc";
  router.push({ query });
};
</script>
