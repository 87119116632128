<template>
  <transition name="slide-up">
    <div
      name="Container"
      id="Container"
      v-if="init"
      class="p-5 bg-white rounded-lg shadow-primary h-auto"
    >
      <template v-if="isLoading">
        <div class="flex items-center justify-center h-96">
          <Loading />
        </div>
      </template>

      <template v-else>
        <slot name="content"></slot>
      </template>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { ref } from "vue";

const init = ref(false);

onMounted(() => {
  init.value = true;
});

defineProps<{
  isLoading?: boolean;
}>();
</script>
