<template>
  <Modal
    :is_visible="storeModal.state.deleteModal.isVisiable"
    @close="() => storeModal.state.deleteModal.onCancel()"
    size="sm"
  >
    <template #body>
      <div class="flex flex-col gap-12">
        <div class="flex items-start justify-between">
          <div class="flex flex-col gap-3 text-tertiaryDark">
            <h2 class="text-xl">
              {{
                storeModal.state.deleteModal.title || $t("delete-confirmation")
              }}
            </h2>
            <p>
              {{
                storeModal.state.deleteModal.message ||
                $t("are-you-sure-you-want-to-delete-this-item")
              }}
            </p>
          </div>
          <Image :src="deleteConfirmationIcon" alt="delete" loading="lazy" />
        </div>

        <div class="flex flex-col gap-2 my-2">
          <label for="reason">{{ $t("reason-for-delete") }}</label>
          <textarea
            id="reason"
            name="reason"
            :placeholder="$t('information-is-repetitive-and-incorrect')"
            v-model="storeModal.state.deleteModal.reason"
            class="p-3 border-none rounded-lg bg-secondary/60"
            rows="3"
          ></textarea>
        </div>
        <div class="flex flex-col gap-2 my-2">
          <label for="adminPassword">{{ $t("admin-password") }}</label>
          <input
            type="password"
            id="adminPassword"
            name="adminPassword"
            :placeholder="$t('admin-password')"
            v-model="storeModal.state.deleteModal.adminPassword"
            class="p-3 border-none rounded-lg bg-secondary/60"
          />
        </div>

        <div class="flex items-center self-end justify-end w-1/2 gap-3">
          <button
            class="button-primary !bg-white border border-primary !text-primary"
            @click="() => storeModal.state.deleteModal.onCancel()"
            type="button"
          >
            {{ $t("no-keep-it") }}
          </button>
          <button
            class="button-primary !border-danger !bg-danger"
            @click="() => storeModal.state.deleteModal.onConform()"
            type="button"
          >
            {{ $t("yes-delete") }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import deleteConfirmationIcon from "@/assets/icons/deleteConfirmationIcon.svg";
import { useModal } from "./store/modalStore";

const storeModal = useModal();
</script>
