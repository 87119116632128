import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import NotFoundPageVue from "@/core/components/layout/NotFoundPage.vue";
import { t } from "@/core/i18n";

import badges from "./badges";
import difficulty from "./difficulty";
import stageLimit from "./stageLimit";
import userLevel from "./userLevel";


const route: RouteRecordRaw = {
  path: "/badges-management/",
  name: "badgesManagement",
  component: RouterView,
  children: [
    {
      path: "",
      name: "badgesManagement",
      component: RouterView,
      meta: {
        breadcrumb: [
          { title: t("badgesManagement"), routeName: "badgesManagement" },
        ],
      },
      children: [
        {
          path: "badges-list",
          name: "BadgesList",
          component: () => import("../screens/BadgesList.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("BadgesList"), routeName: "BadgesList" },
            ]
          },
        },
        {
          path: "create-badge",
          name: "CreateBadge",
          component: () => import("../screens/BadgeForm.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("BadgesList"), routeName: "BadgesList" },
              { title: t('create-badge'), routeName: "CreateBadge" },
            ]
          },
        },
        {
          path: "update-badge/:id",
          name: "UpdateBadge",
          component: () => import("../screens/BadgeForm.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("BadgesList"), routeName: "BadgesList" },
              { title: t('update-badge'), routeName: "UpdateBadge" },
            ]
          },
        },
        {
          path: "",
          name: "Redirect",
          redirect: "DifficultyList",
        },
      ]
    },
    {
      path: "difficulty-management/",
      component: RouterView,
      children: [
        {
          path: "",
          name: "DifficultyManagement",
          component: () => import("../screens/DifficultyList.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("DifficultyManagement"), routeName: "DifficultyManagement" },
            ]
          },
        },
        {
          path: "create-difficulty",
          name: "CreateDifficulty",
          component: () => import("../screens/DifficultyForm.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("DifficultyManagement"), routeName: "DifficultyManagement" },
              { title: t('create-difficulty'), routeName: "CreateDifficulty" },
            ]
          },
        },
        {
          path: "update-difficulty/:id",
          name: "UpdateDifficulty",
          component: () => import("../screens/DifficultyForm.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("DifficultyManagement"), routeName: "DifficultyManagement" },
              { title: t('update-difficulty'), routeName: "UpdateDifficulty" },
            ]
          },
        },
        {
          path: "",
          name: "Redirect",
          redirect: "DifficultyList",
        },
      ]
    },
    {
      path: "subject-stages-controls/",
      name: "SubjectStagesControlsManagement",
      component: RouterView,
      children: [
        {
          path: "",
          name: "StageLimits",
          component: () => import("../screens/StageLimits.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
              { title: t("SubjectStagesControls"), routeName: "StageLimits" },
            ]
          },
        },
        {
          path: "edit-multipliers",
          name: "EditMultipliers",
          component: () => import("../screens/EditMultipliers.vue"),
          meta: {
            breadcrumb: [
              { title: t("badgesManagement"), routeName: "badgesManagement" },
            ],
          }
        },
        {
          path: "",
          name: "Redirect",
          redirect: "StageLimits",
        },
      ]
    },
    {
      path: "user-levels-controls",
      name: "UserLevelsControls",
      component: () => import("../screens/UserLevelsControls.vue"),
      meta: {
        breadcrumb: [
          { title: t("badgesManagement"), routeName: "badgesManagement" },
          { title: t("UserLevelsControls"), routeName: "UserLevelsControls" },
        ]
      },
    },
   
    
    {
      path: "",
      name: "Redirect",
      redirect: "BadgesList",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPageVue,
    },
  ],
};

export { route, badges, difficulty, stageLimit, userLevel };
