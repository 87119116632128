<template>
    <div class="flex flex-col items-center justify-center h-screen gap-5 text-center">
        <h1 class="text-5xl font-bold text-primary">404</h1>
        <h2 class="text-2xl">Page not found</h2>
        <router-link to="/" class="px-4 py-3 underline rounded-lg text-tertiary bg-primary">Go to home</router-link>
    </div>
</template>

<script lang="ts" setup>

</script>

<style>

</style>