<template>
  <div v-bind="$attrs">
    <ul class="flex items-center justify-start">
      <li
        @click="activeTab = tab.key"
        v-for="tab in tabs"
        :key="tab.key"
        class="px-5 py-2 text-xs rounded-t-lg cursor-pointer"
        :class="
          tab.key == activeTab
            ? 'bg-primary text-white'
            : 'bg-dark100 text-primaryDark/50'
        "
      >
        <span>
          {{ tab.value }}
        </span>
      </li>
    </ul>
    <div class="p-5 border border-dark700" :class="contentClass">
      <template v-for="tab in tabs" :key="tab.key">
        <slot :name="tab.key" v-if="tab.key == activeTab"></slot>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  tabs: KeyVal[];
  contentClass?: string;
}>();

const activeTab = ref<string>(props.tabs[0].key);
</script>
