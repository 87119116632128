import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Audits";

const MODULE_TITLE = t("log-records");

const NAME = "Logs";

const tableHeader = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("log-type"),
    value: "title",
  },
  {
    label: t("log-severity"),
    value: "severity",
  },
  {
    label: t("log-location"),
    value: "location",
  },
  {
    label: t("by"),
    value: "userId",
  },
  {
    label: t("date-and-time"),
    value: "createdAt",
  },
  {
    label: t("actions"),
    value: "actions",
  },
];

const settings = {
  hasView: false,
  hasExport: true,
  hasBlock: false,
  hasDelete: false,
  hasNotify: false,
  hasFilter: false,
  hasSearch: true,
  hasEdit: false,
  rowsOptions: {
    hasView: true,
    hasBlock: false,
    hasDelete: false,
    hasEdit: false,
    isCenter: false,
  } as RowsOptions,
};
const route: RouteRecordRaw = {
  name: "Logs",
  path: "/logs/",
  component: RouterView,
  meta: {
    breadcrumb: [{ title: t("Logs"), routeName: "Logs" }],
  },
  children: [
    {
      path: "all-logs",
      name: "AllLogs",
      component: () => import("./screens/AllLogs.vue"),
      meta: {
        breadcrumb: [
          { title: t("Logs"), routeName: "Logs" },
          { title: t("AllLogs"), routeName: "AllLogs" },
        ],
      },
    },
    {
      path: "system-logs",
      name: "SystemLogs",
      component: () => import("./screens/SystemLogs.vue"),
      meta: {
        breadcrumb: [
          { title: t("Logs"), routeName: "Logs" },
          { title: t("SystemLogs"), routeName: "SystemLogs" },
        ],
      },
    },
    {
      path: "admin-logs",
      name: "AdminLogs",
      component: () => import("./screens/AdminLogs.vue"),
      meta: {
        breadcrumb: [
          { title: t("Logs"), routeName: "Logs" },
          { title: t("AdminLogs"), routeName: "AdminLogs" },
        ],
      },
    },
    {
      path: "achievements-logs",
      name: "AchievementsLogs",
      component: () => import("./screens/AchievementsLogs.vue"),
      meta: {
        breadcrumb: [
          { title: t("Logs"), routeName: "Logs" },
          { title: t("AchievementsLogs"), routeName: "AchievementsLogs" },
        ],
      },
    },
    {
      path: "reports-logs",
      name: "ReportsLogs",
      component: () => import("./screens/ReportsLogs.vue"),
      meta: {
        breadcrumb: [
          { title: t("Logs"), routeName: "Logs" },
          { title: t("ReportsLogs"), routeName: "ReportsLogs" },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "AllLogs",
      meta: {
        breadcrumb: t("AllLogs"), // For breadcrumbs in navbar
      },
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route, API_URL, MODULE_TITLE, NAME, settings, tableHeader };
