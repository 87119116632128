import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/LeaderBoard";

const route: RouteRecordRaw = {
  path: "/leader-board/",
  name: "LeaderBoardVue",
  component: RouterView,
  meta: {
    permission: 8,
    breadcrumb: [{ title: t("LeaderBoard"), routeName: "LeaderBoardVue" }],
  },
  children: [
    {
      path: "students-leader-board",
      name: "StudentsLeaderBoard",
      component: () => import("./screens/StudentsLeaderBoard.vue"),
      meta: {
        permission: 8,
        breadcrumb: [
          { title: t("LeaderBoard"), routeName: "LeaderBoardVue" },
          { title: t("studentsleaderboard"), routeName: "StudentsLeaderBoard" },
        ],
      },
    },
    {
      path: "adults-leader-board",
      name: "AdultsLeaderBoard",
      component: () => import("./screens/AdultsLeaderBoard.vue"),
      meta: {
        permission: 8,
        breadcrumb: [
          { title: t("LeaderBoard"), routeName: "LeaderBoardVue" },
          { title: t("AdultsLeaderBoard"), routeName: "AdultsLeaderBoard" },
        ],
      },
    },
    {
      
      path: "teachers-leader-board",
      name: "TeachersLeaderBoard",
      component: () => import("./screens/TeachersLeaderBoard.vue"),
      meta: {
        permission: 8,
        breadcrumb: [
          { title: t("LeaderBoard"), routeName: "LeaderBoardVue" },
          { title: t("TeachersLeaderBoard"), routeName: "TeachersLeaderBoard" },
        ],
      },
    },
    {
      path: "school-leader-boards",
      name: "SchoolLeaderBoards",
      component: () => import("./screens/SchoolLeaderBoards.vue"),
      meta: {
        permission: 8,
        breadcrumb: [
          { title: t("LeaderBoard"), routeName: "LeaderBoardVue" },
          {
            title: t("school-leader-boards"),
            routeName: "SchoolLeaderBoards",
          },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "StudentsLeaderBoard",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

const NAME = "leader-board";

const tableHeader: LabelVal[] = [
  {
    label: t("rank"),
    value: "Rank",
  },
  {
    label: t("name"),
    value: "name",
  },
  {
    label: t("grade"),
    value: "Grade",
  },
  {
    label: t("school"),
    value: "School",
  },
  {
    label: t("level"),
    value: "Level",
  },
  {
    label: t("score"),
    value: "Score",
  },

  {
    label: t("country"),
    value: "Country",
  },

  {
    label: t("city"),
    value: "City",
  },
];
const schoolTableHeader: LabelVal[] = [
  {
    label: t("rank"),
    value: "Rank",
  },
  {
    label: t("school"),
    value: "School",
  },
  {
    label: t("score"),
    value: "Score",
  },

  {
    label: t("country"),
    value: "Country",
  },

  {
    label: t("city"),
    value: "City",
  },
];

export { route, NAME, tableHeader, schoolTableHeader, API_URL };
