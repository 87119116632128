<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="7"
    viewBox="0 0 13 7"
  >
    <path
      id="Polygon_48"
      data-name="Polygon 48"
      d="M6.134.395a.5.5,0,0,1,.733,0L12.22,6.16a.5.5,0,0,1-.366.84H1.147A.5.5,0,0,1,.78,6.16Z"
      transform="translate(13 7) rotate(180)"
      fill="#005caa"
    />
  </svg>
</template>
