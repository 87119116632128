<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.602"
    height="16.602"
    viewBox="0 0 16.602 16.602"
    fill="currentColor"
  >
    <path
      id="add_FILL0_wght200_GRAD0_opsz48"
      d="M16.531,22.74V17.208H11v-.677h5.531V11h.677v5.531H22.74v.677H17.208V22.74Z"
      transform="translate(8.301 -15.556) rotate(45)"
    />
  </svg>
</template>
