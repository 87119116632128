<template>
  <button
    @click="$emit('click')"
    type="button"
    v-bind="$attrs"
    class="w-[35px] h-[18px] flex items-center border-2 rounded-lg transition-all duration-100"
    :class="cssClass"
  >
    <div class="h-[14px] w-[14px] rounded-full bg-white"></div>
  </button>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { PropType } from "vue";

type Color =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "white"
  | "transparent";

const props = defineProps({
  isActive: {
    type: Boolean,
    required: true,
  },
  color: {
    type: String as PropType<Color>,
    required: false,
    default: "primary",
  },
});

defineEmits(["click"]);

const cssClass = computed(() => {
  let bg = `bg-${props.color}`,
    border = `border-${props.color}`;

  return props.isActive
    ? `${bg} ${border} justify-end`
    : "bg-dark500 border-2 border-dark500 justify-start ";
});
</script>
