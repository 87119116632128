<template>
  <button
    v-bind="$attrs"
    @click="$router.back()"
    class="btn gap-2 !px-4 !rounded-lg bg-white border text-primary border-primary"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      :class="locale == 'ar' ? 'transform rotate-180' : ''"
    >
      <path
        id="arrow_back_FILL0_wght400_GRAD0_opsz48"
        d="M20,32,8,20,20,8l1.575,1.575-9.3,9.3H32v2.25H12.275l9.3,9.3Z"
        transform="translate(-8 -8)"
        fill="#005caa"
      />
    </svg>
    <span>{{ $t("back") }}</span>
  </button>
</template>
<script setup lang="ts">
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
</script>
