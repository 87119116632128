import { t } from '@/core/i18n'

const API_URL = '/v1.0/management/Cities'

const MODULE_TITLE = t('list-of-cities')

const NAME = 'cities'

const settings = {
  hasView: false,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  rowsOptions: {
    hasView: false,
    hasBlock: false,
    hasDelete: false,
    hasEdit: true,
  },
}



const tableHeader: LabelVal[] = [
  {
    label: 'id',
    value: 'id',
  },
  {
    label: t('city-name-ar'),
    value: 'cityNameAr',
  },
  {
    label: t('city-name-en'),
    value: 'cityNameEn',
  },
  {
    label: t('no-of-schools'),
    value: 'numberOfSchools',
  },
  {
    label: t('created-on'),
    value: 'createdAt',
  },
  {
    label: t('action'),
    value: 'Action',
  },
]

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
}
