<template>
  <div class="relative z-50">
    <button
      @click="isDropdownVisible = !isDropdownVisible"
      class="flex items-center justify-center gap-2 px-3 py-2 font-medium rounded-lg bg-tertiary"
    >
      <NotificationIcon />
    </button>
    <transition name="fade" :duration="150">
      <div
        v-if="isDropdownVisible"
        class="absolute bg-white rounded-2xl shadow-xl z-50 w-[430px] h-auto p-5 transform -translate-x-3/4 flex flex-col gap-4 max-h-[600px] overflow-y-auto customScrollBar"
      >
        <NotificationItem
          v-for="(item, index) in notifications"
          :item="item"
          :key="index"
          @remove="removeNotification"
        />
        <div
          v-if="loading"
          class="flex items-center justify-center my-4 rounded-lg"
        >
          <Loading />
        </div>
        <button
          @click="() => getNotifications({ pageNumber: currentPage + 1 })"
          class="p-2 flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="11.792"
            viewBox="0 0 20 11.792"
          >
            <g
              id="Group_9209"
              data-name="Group 9209"
              transform="translate(-1544 -995)"
            >
              <path
                id="expand_more_FILL0_wght400_GRAD0_opsz48"
                d="M250,439.792l-10-10L241.792,428,250,436.25l8.208-8.208L260,429.833Z"
                transform="translate(1304 567)"
                fill="#005caa"
              />
            </g>
          </svg>
        </button>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import NotificationIcon from "@/core/components/icons/NotificationsIcon.vue";
import NotificationItem from "./NotificationItem.vue";
import { ref } from "vue";
import { useHttpClient } from "@/core/network/httpClient";
import { watch } from "vue";
import { number } from "yup";
import { useToast } from "vue-toastification";

const httpClient = useHttpClient();
const toast = useToast();

const loading = ref<boolean>(false);
const isDropdownVisible = ref(false);
const notifications = ref<MyNotification[]>([]);
const currentPage = ref<number>(1);
const totalPages = ref<number>(1);

watch(
  () => isDropdownVisible.value,
  (value) => {
    if (value) {
      getNotifications({ pageNumber: 1 });
    }
  }
);

const getNotifications = async ({ pageNumber }: { pageNumber: number }) => {
  if (pageNumber > totalPages.value) return;

  loading.value = true;
  try {
    const { data } = await httpClient.get("/v1.0/management/Notifications", {
      params: {
        pageNumber,
        pageSize: 10,
      },
    });

    currentPage.value = data.currentPage;

    if (pageNumber === 1) {
      notifications.value = data.content;
    } else {
      // remove duplicates items and push
      notifications.value = [
        ...new Map(
          [...notifications.value, ...data.content].map((item) => [
            item.id,
            item,
          ])
        ).values(),
      ];
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const removeNotification = async (id: number) => {
  try {
    const { data } = await httpClient.delete(
      `/v1.0/management/Notifications/${id}`,
      {
        data: {},
      }
    );
    notifications.value = notifications.value.filter(
      (notification) => notification.id !== id
    );
    toast.success(data.message);
  } catch (error) {
    console.log(error);
  }
};
</script>
