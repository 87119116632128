<template>
  <div class="flex items-center" v-bind="$attrs">
    <button
      @click="handleChange"
      class="h-4 w-4 p-[2px] rounded-[3px] flex items-center justify-center border"
      :class="
        !!checked ? 'border-primary' : 'bg-dark100 border-tertiaryDark/30'
      "
    >
      <span
        class="h-full w-full rounded-[3px] bg-primary"
        :class="!!checked ? 'block' : 'hidden'"
      ></span>
    </button>
    <label>{{ label }}</label>
  </div>
</template>
<script setup lang="ts">
defineProps({
  checked: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["change"]);

const handleChange = () => {
  emit("change");
};
</script>
