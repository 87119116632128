<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.285" height="18.057" viewBox="0 0 17.285 18.057">
          <g id="Group_8377" data-name="Group 8377" transform="translate(-1789 -25.973)">
            <path id="Path_17308" data-name="Path 17308" d="M-16644.814-18540h10.084v16.057h-10.084"
              transform="translate(18440.016 18566.973)" fill="none" stroke="#005caa" stroke-linecap="round"
              stroke-width="2" />
            <path id="Path_17309" data-name="Path 17309" d="M-16641.367-18531.814h-8.068"
              transform="translate(18442.602 18566.814)" fill="none" stroke="#da2834" stroke-linecap="round"
              stroke-width="2" />
            <g id="Polygon_45" data-name="Polygon 45" transform="translate(1789 39) rotate(-90)" fill="none">
              <path d="M4,0,8,6H0Z" stroke="none" />
              <path
                d="M 3.999999761581421 3.605550050735474 L 3.737032890319824 4 L 4.262966156005859 4 L 3.999999761581421 3.605550050735474 M 3.999999761581421 0 L 8 6 L -4.76837158203125e-07 6 L 3.999999761581421 0 Z"
                stroke="none" fill="#da2834" />
            </g>
          </g>
        </svg>
</template>