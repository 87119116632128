<template>
  <transition name="modal">
    <div
      v-bind="$attrs"
      v-if="is_visible"
      ref="dialog"
      role="dialog"
      id="dialog"
      class="modal-mask"
    >
      <div class="flex items-center justify-center h-screen">
        <div
          :class="getModalWidth"
          id="dialogContent"
          class="max-h-screen p-5 mx-auto overflow-y-auto customScrollBar transition-all duration-300 bg-white modal-container shadow-primary rounded-2xl min-h-fit"
        >
          <slot name="body"> </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { PropType } from "vue";
import {
  onMounted,
  nextTick,
  computed,
  onUnmounted,
} from "vue";



const emit = defineEmits(["close"]);

type ModalSize = "sm" | "md" | "lg";

const props = defineProps({
  is_visible: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<ModalSize>,
    default: "md",
  },
});

const getModalWidth = computed(() => {
  switch (props.size) {
    case "sm":
      return "w-3/4 md:w-3/4 lg:w-1/3 ";
    case "md":
      return "w-11/12	md:w-3/4 lg:w-1/2 ";
    case "lg":
      return "w-11/12	md:w-4/5 lg:w-3/4 ";
    default:
      return "w-11/12	md:w-4/5 lg:w-3/4 ";
  }
});

const handleClose = () => {
  emit("close");
};

// listen to escape key to close the modal
const handleEscape = (e: KeyboardEvent) => {
  if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
    handleClose();
  }
};

// listen to click outside to close the modal
const handleClickOutside = (e: MouseEvent) => {
  const dialog = document.getElementById("dialog");
  const dialogContent = document.getElementById("dialogContent");
  if (dialog && dialogContent) {
    if (
      dialog.contains(e.target as Node) &&
      !dialogContent.contains(e.target as Node)
    ) {
      handleClose();
    }
  }
};

onMounted(() => {
  nextTick(() => {
    // add event listeners
    document.addEventListener("keydown", handleEscape);
    // add event listeners
    document.body.addEventListener("click", handleClickOutside);
  });
});

onUnmounted(() => {
  // remove event listeners
  document.removeEventListener("keydown", handleEscape);
  document.body.removeEventListener("click", handleClickOutside);
});
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
