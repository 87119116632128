import { CoreUserType, NotificationPriority } from "@/core/enums";
import { t } from "@/core/i18n";

const NotificationTypesAPI_URL = "/v1.0/management/Settings/Notification-Types";
const NotificationPresetsAPI_URL =
  "/v1.0/management/Settings/Notification-Presets";

const pushNotificationBASE_URL = "/v1.0/management/Settings/Push-Notification";

const NAME = "AppNotificationsManagement";

const notificationsTypesTableHeader: LabelVal[] = [
  {
    label: t("type-of-notification"),
    value: "typeOfNotification",
  },
  {
    label: t("priority"),
    value: "Priority",
  },
  {
    label: t("status"),
    value: "Status",
  },
  {
    label: t("target"),
    value: "Target",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

const presetsTableHeader: LabelVal[] = [
  {
    label: t("preset-name"),
    value: "PresetName",
  },
  {
    label: t("priority"),
    value: "Priority",
  },
  {
    label: t("filters"),
    value: "Filters",
  },
  {
    label: t("target"),
    value: "Target",
  },
  {
    label: t("link"),
    value: "Link",
  },
  {
    label: t("action"),
    value: "Action",
  },
];
const AppNotificationRecordTableHeader: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("notification-title"),
    value: "NotificationTitle",
  },
  {
    label: t("type"),
    value: "Type",
  },
  {
    label: t("arabic-v"),
    value: "Arabic V",
  },
  {
    label: t("english-v"),
    value: "English V",
  },
  {
    label: t("created-on-0"),
    value: "Created On",
  },
  {
    label: t("sent-on"),
    value: "Sent On",
  },
  {
    label: t("status"),
    value: "Status",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

const prioritiesOptions = [
  { id: NotificationPriority.Low, name: t("low") },
  { id: NotificationPriority.Medium, name: t("medium") },
  { id: NotificationPriority.Critical, name: t("critical") },
  { id: NotificationPriority.High, name: t("high") },
];

const targetsList = [
  { id: CoreUserType.Student, name: t("students") },
  { id: CoreUserType.Teacher, name: t("teachers") },
  { id: CoreUserType.Parent, name: t("parents") },
];

export default {
  NAME,
  NotificationTypesAPI_URL,
  notificationsTypesTableHeader,
  prioritiesOptions,
  targetsList,
  NotificationPresetsAPI_URL,
  presetsTableHeader,
  pushNotificationBASE_URL,
  AppNotificationRecordTableHeader,
};
