import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

const route: RouteRecordRaw = {
  component: RouterView,
  name: "Dashboard",
  path: "/dashboard/",
  meta: {
    breadcrumb: [{ title: t("Dashboard"), routeName: "Dashboard" }],
  },
  children: [
    {
      path: "students-dashboard",
      name: "StudentsDashboard",
      component: () => import("./screens/StudentsDashboard.vue"),
      meta: {
        breadcrumb: [
          { title: t("Dashboard"), routeName: "Dashboard" },
          {
            title: t("StudentsDashboard"),
            routeName: "StudentsDashboard",
          },
        ],
      },
    },
    {
      path: "teachers-dashboard",
      name: "TeachersDashboard",
      component: () => import("./screens/TeachersDashboard.vue"),
      meta: {
        breadcrumb: [
          { title: t("Dashboard"), routeName: "Dashboard" },
          {
            title: t("TeachersDashboard"),
            routeName: "TeachersDashboard",
          },
        ],
      },
    },
    {
      path: "parents-dashboard",
      name: "ParentsDashboard",
      component: () => import("./screens/ParentsDashboard.vue"),
      meta: {
        breadcrumb: [
          { title: t("Dashboard"), routeName: "Dashboard" },
          {
            title: t("ParentsDashboard"),
            routeName: "ParentsDashboard",
          },
        ],
      },
    },
    {
      path: "",
      name: "Redirect",
      redirect: "StudentsDashboard",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: () => import("@/core/components/layout/NotFoundPage.vue"),
    },
  ],
};

export { route };
