import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Settings/Contents";

const NAME = "ContentManagement";

const tableHeader: LabelVal[] = [
  {
    label: t("title"),
    value: "title",
  },
  {
    label: t("last-updated"),
    value: "modifiedOn",
  },
  {
    label: t("english-version"),
    value: "englishVersion",
  },
  {
    label: t("arabic-version"),
    value: "arabicVersion",
  },
  {
    label: t("actions"),
    value: "actions",
  },
];

export default {
  tableHeader,
  NAME,
  API_URL,
};
