import NotFoundPage from "@/core/components/layout/NotFoundPage.vue";
import { RouterView, type RouteRecordRaw } from "vue-router";
import { t } from "@/core/i18n";

const route: RouteRecordRaw = {
  path: "/",
  component: RouterView,
  name: "Main",
  meta: {
    breadcrumb: [
      { title: t("Home"), routeName: "Home" },
    ]
  },
  children: [
    {
      path: "/",
      name: "Home",
      component: () => import("./screens/Home.vue"),
      meta: {
        breadcrumb: [
          { title: t("Home"), routeName: "Home" },
        ]
      }
    },
    {
      path: "admin-profile",
      name: "AdminProfile",
      component: () => import("./screens/AdminProfile.vue"),
      meta: {
        breadcrumb: [
          { title: t("Home"), routeName: "Home" },
          { title: t("AdminProfile"), routeName: "AdminProfile" },
        ]
      }
    },
    {
      path: "system-statistics",
      name: "SystemStatistics",
      component: () => import("./screens/SystemStatistics.vue"),
      meta: {
        breadcrumb: [
          { title: t("Home"), routeName: "Home" },
          { title: t("SystemStatistics"), routeName: "SystemStatistics" },
        ]
      }
    },
    {
      path: "",
      name: "Redirect",
      redirect: "/",
    },
    {
      // the 404 route, when none of the above matches
      path: ":catchAll(.*)",
      name: "404",
      component: NotFoundPage,
    },
  ],
};

export { route };
