<template>
  <section
    class="w-full py-2 px-5 z-10 bg-tertiary border-b-[1px] border-tertiary flex items-center"
  >
    <div class="container px-5 mx-auto">
      <ul class="flex items-center justify-start gap-2">
        <li v-for="(item, index) in breadcrumb" :key="index">
          <router-link
            :to="{ name: item.routeName }"
            :class="
              item.routeName == router.name && 'underline !text-primaryDark'
            "
            class="text-xs font-normal text-primary hover:underline"
          >
            <span>
              {{ item.title }}
            </span>
            <span v-if="index < breadcrumb.length - 1"> > </span>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>
<script setup lang="ts">
import { watch, ref, type Ref } from "vue";
import { useRoute } from "vue-router";

const router = useRoute();

const breadcrumb: Ref<Breadcrumb[]> = ref(router.meta.breadcrumb) as Ref<
  Breadcrumb[]
>;

watch(router, (router) => {
  breadcrumb.value = router.meta.breadcrumb as Breadcrumb[];
});
</script>
