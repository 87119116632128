import { t } from "@/core/i18n";

const API_URL = "/v1.0/management/Exercises";

const NAME = "ExerciseManagement";

const tableHeader: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("year"),
    value: "Year",
  },
  {
    label: t("semester"),
    value: "Semester",
  },
  {
    label: t("chapter"),
    value: "Chapter",
  },
  {
    label: t("lesson"),
    value: "Lesson",
  },
  {
    label: t("no-of-questions"),
    value: "NoOfQuestions",
  },
  {
    label: t("action"),
    value: "Action",
  },
];

const createQuestionTable: LabelVal[] = [
  {
    label: "id",
    value: "id",
  },
  {
    label: t("order"),
    value: "order",
  },
  {
    label: t("type"),
    value: "type",
  },
  {
    label: t("answer"),
    value: "answer",
  },
  {
    label: t("image"),
    value: "image",
  },
  {
    label: t("action"),
    value: "Action",
  },
];


const settings: AppContainerSettings = {
  hasView: true,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  notSelectable: true,
  isDraggable: true,
  rowsOptions: {
    isCenter: false,
    hasView: true,
    hasBlock: false,
    hasDelete: false,
    hasEdit: false,
  },
};
export default {
  API_URL,
  createQuestionTable,
  NAME,
  tableHeader,
  settings,
};
