import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import DashboardLayout from '@/core/components/layout/dashboardView/DashboardLayout.vue'
import NotFoundPage from '@/core/components/layout/NotFoundPage.vue'

// hooks
import { useAuth } from '@/modules/auth/stores/auth'
import useHasRole from '@/core/hooks/useHasRole'

import { route as AuthRoutes } from './modules/auth/config'
import { route as HomeRoutes } from './modules/Home/config'
import { route as ReportRoutes } from './modules/reports/config'
import { route as BadgesRoutes } from './modules/Badges/config'
import { route as DashboardRoutes } from './modules/Dashboard/config'
import { route as LeaderBoardRoutes } from './modules/LeaderBoard/config'
import { route as LogsRoutes } from './modules/LogsManagement/config'
import { route as QuizRoutes } from './modules/QuizManagement/config'
import { route as SettingRoutes } from './modules/Settings/config'
import { route as SubjectsAndGradeRoutes } from './modules/SubjectsAndGrade/config'
import { route as UsersRoutes } from './modules/userManagement/config'
import { route as SupportFAQRoutes } from './modules/SupportFAQ/config'


export const routes: RouteRecordRaw[] = [
  AuthRoutes,
  {
    path: '/',
    component: DashboardLayout,
    meta: {
      guest: false,
    },
    children: [
      HomeRoutes,
      ReportRoutes,
      BadgesRoutes,
      DashboardRoutes,
      LeaderBoardRoutes,
      LogsRoutes,
      QuizRoutes,
      SettingRoutes,
      SubjectsAndGradeRoutes,
      SupportFAQRoutes,
      UsersRoutes,
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: NotFoundPage,
    meta: {
      guest: false,
    },
  },
  {
    path: '/Forbidden',
    name: 'Forbidden',
    component: () => import('@/core/components/layout/Forbidden.vue'),
    meta: {
      guest: false,
    },
  },
  { path: '/:pathMatch(.*)*', redirect: '/404' },
];


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const auth = useAuth()
  const { has } = useHasRole()

  // if the route is guest only then let the user continue
  if (to.meta.guest) {
    document.getElementById('InitScreenDOM')?.remove()
    return next()
  }

  if (!auth.user) {
    const res = await auth.getProfile()
    document.getElementById('InitScreenDOM')?.remove()
    

    if (res) {
      // if the user is logged in and he doesn't have permission to access the route then redirect to forbidden
      if (!has(to.meta.permission as number)) {
        return next('/Forbidden')
      }

      // the user is logged in and trying to access the login page then redirect to dashboard
      if (to.meta.guest) {
        return next('/dashboard')
      }

      // continue to the route
      return next()
    }

    // if the user is not logged in and the route is not guest only then redirect to login
    if (to.meta.guest) {
      return next()
    }

    return next('/auth/login')
  }

      // if the user is logged in and he doesn't have permission to access the route then redirect to forbidden
  if (!has(to.meta.permission as number)) {
    document.getElementById('InitScreenDOM')?.remove()
    return next('/Forbidden')
  }

  // otherwise continue to the route
  document.getElementById('InitScreenDOM')?.remove()
  next()

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
