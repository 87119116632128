<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.639" height="23.999"
        viewBox="0 0 17.639 23.999">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_3143" data-name="Rectangle 3143" width="17.639" height="23.999" fill="#005caa" />
            </clipPath>
        </defs>
        <g id="Group_7069" data-name="Group 7069" transform="translate(0 0)">
            <g id="Group_7068" data-name="Group 7068" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Path_10565" data-name="Path 10565"
                    d="M9.041,27.911a1.721,1.721,0,1,1,3.443,0,1.688,1.688,0,0,1-.1.569,1.717,1.717,0,0,1-3.245,0,1.688,1.688,0,0,1-.1-.569"
                    transform="translate(-1.946 -5.636)" fill="#005caa" />
                <path id="Path_10566" data-name="Path 10566"
                    d="M9.041,1.724a1.721,1.721,0,1,1,3.443,0,1.688,1.688,0,0,1-.1.569,1.717,1.717,0,0,1-3.245,0,1.688,1.688,0,0,1-.1-.569"
                    transform="translate(-1.946 0)" fill="#005caa" />
                <path id="Path_10567" data-name="Path 10567"
                    d="M9.167,2.156a8.811,8.811,0,0,1,3.245,0,1.717,1.717,0,0,1-3.245,0"
                    transform="translate(-1.973 -0.432)" fill="#005caa" />
                <path id="Path_10568" data-name="Path 10568"
                    d="M0,15.325a9.193,9.193,0,0,0,.936,2.456,9.013,9.013,0,0,0,7.878,4.726A9,9,0,0,0,16.7,17.776a9.141,9.141,0,0,0,.941-2.451,6.733,6.733,0,0,1-2.306-2.444,6.445,6.445,0,0,1-.691-4.221,1.014,1.014,0,0,1,.029-.133,6.287,6.287,0,0,1,1.353-2.81,9.033,9.033,0,0,0-1.91-1.91A8.812,8.812,0,0,0,10.439,2.2a1.717,1.717,0,0,1-3.245,0,8.815,8.815,0,0,0-3.67,1.6,9.1,9.1,0,0,0-1.91,1.921A6.3,6.3,0,0,1,2.966,8.538c.005.041.018.081.024.122A6.4,6.4,0,0,1,2.3,12.887,6.749,6.749,0,0,1,0,15.325m4.454-.836A8.856,8.856,0,0,0,5.678,8.166c-.029-.168-.063-.337-.1-.5A8.517,8.517,0,0,0,5,6.047a6.361,6.361,0,0,1,7.623-.005,8.89,8.89,0,0,0-.569,1.625c-.041.162-.075.331-.1.5A8.872,8.872,0,0,0,13.185,14.5a9,9,0,0,0,1.387,1.718,6.435,6.435,0,0,1-11.513,0,9.013,9.013,0,0,0,1.394-1.724"
                transform="translate(0 -0.473)" fill="#005caa" />
            <path id="Path_10569" data-name="Path 10569" d="M19.539,15.81l2.3,2.445a6.739,6.739,0,0,1-2.3-2.445"
                transform="translate(-4.205 -3.403)" fill="#005caa" />
            <path id="Path_10570" data-name="Path 10570" d="M0,18.256l2.3-2.438A6.747,6.747,0,0,1,0,18.256"
                transform="translate(0 -3.404)" fill="#005caa" />
        </g>
    </g>
</svg></template>