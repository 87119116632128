import BadgesListVue from "../components/icons/BadgesList.vue";
import DashboardVue from "../components/icons/Dashboard.vue";
import HomeVue from "../components/icons/Home.vue";
import LeaderBoardVue from "../components/icons/LeaderBoard.vue";
import LogsVue from "../components/icons/Logs.vue";
import QuizManagementVue from "../components/icons/QuizManagement.vue";
import ReportsVue from "../components/icons/Reports.vue";
import SettingsVue from "../components/icons/Settings.vue";
import SubjectAndGradesVue from "../components/icons/SubjectAndGrades.vue";
import SupportFAQVue from "../components/icons/SupportFAQ.vue";
import UserManagmentVue from "../components/icons/UserManagment.vue";
import { Permission } from "../enums";
import { t } from "../i18n";

const Home: NavLink = {
  title: t("Home"),
  route_name: "Home",
  icon: HomeVue,
  allowedPermission: 0,
  children: [
    {
      title: t("Home"),
      route_name: "Home",
      allowedPermission: 0,
    },
    {
      title: t("AdminProfile"),
      route_name: "AdminProfile",
      allowedPermission: 0,
    },
    {
      title: t("SystemStatistics"),
      route_name: "SystemStatistics",
      allowedPermission: 0,
    },
  ],
};

const BadgesList: NavLink = {
  title: t("BadgesList"),
  route_name: "badgesManagement",
  icon: BadgesListVue,
  allowedPermission: 0,
  children: [
    {
      title: t("BadgesList"),
      route_name: "BadgesList",
      allowedPermission: 0,
    },
    {
      title: t("UserLevelsControls"),
      route_name: "UserLevelsControls",
      allowedPermission: 0,
    },
    {
      title: t("SubjectStagesControls"),
      route_name: "StageLimits",
      allowedPermission: 0,
    },
    {
      title: t("DifficultyManagement"),
      route_name: "DifficultyManagement",
      allowedPermission: 0,
    },
  ],
};

const LeaderBoard: NavLink = {
  title: t("LeaderBoard"),
  route_name: "leader-board",
  icon: LeaderBoardVue,
  allowedPermission: 0,
  children: [
    {
      title: t("StudentsLeaderboard"),
      route_name: "StudentsLeaderBoard",
      allowedPermission: 0,
    },
    {
      title: t("AdultsLeaderBoard"),
      route_name: "AdultsLeaderBoard",
      allowedPermission: 0,
    },
    {
      title: t("TeachersLeaderBoard"),
      route_name: "TeachersLeaderBoard",
      allowedPermission: 0,
    },
    {
      title: t("school-leader-boards"),
      route_name: "SchoolLeaderBoards",
      allowedPermission: 0,
    },
  ],
};

const Logs: NavLink = {
  title: t("Logs"),
  route_name: "logs",
  icon: LogsVue,
  allowedPermission: 0,
  children: [
    {
      title: t("AllLogs"),
      route_name: "AllLogs",
      allowedPermission: 0,
    },
    {
      title: t("SystemLogs"),
      route_name: "SystemLogs",
      allowedPermission: 0,
    },
    {
      title: t("AdminLogs"),
      route_name: "AdminLogs",
      allowedPermission: 0,
    },
    {
      title: t("AchievementsLogs"),
      route_name: "AchievementsLogs",
      allowedPermission: 0,
    },
    {
      title: t("ReportsLogs"),
      route_name: "ReportsLogs",
      allowedPermission: 0,
    },
  ],
};

const QuizManagement: NavLink = {
  title: t("QuizManagement"),
  route_name: "quiz-management",
  icon: QuizManagementVue,
  allowedPermission: 0,
  children: [
    {
      title: t("QuizManagement"),
      route_name: "QuizManagement",
      allowedPermission: 0,
    },
    {
      title: t("ExamsManagement"),
      route_name: "ExamsManagement",
      allowedPermission: 0,
    },
    {
      title: t("exercise-management"),
      route_name: "ExerciseManagement",
      allowedPermission: 0,
    },
    // {
    //   title: t("QuizCategories"),
    //   route_name: "QuizCategories",
    //   allowedPermission: 0,
    // },
    {
      title: t("ImportQuestions"),
      route_name: "ImportQuestions",
      allowedPermission: 0,
    },
  ],
};

const Dashboard: NavLink = {
  title: t("Dashboard"),
  route_name: "dashboard",
  icon: DashboardVue,
  allowedPermission: 0,
  children: [
    {
      title: t("StudentsDashboard"),
      route_name: "StudentsDashboard",
      allowedPermission: 0,
    },
    {
      title: t("TeachersDashboard"),
      route_name: "TeachersDashboard",
      allowedPermission: 0,
    },
    {
      title: t("ParentsDashboard"),
      route_name: "ParentsDashboard",
      allowedPermission: 0,
    },
  ],
};

const Reports: NavLink = {
  title: t("Reports"),
  route_name: "reports",
  icon: ReportsVue,
  allowedPermission: 0,
  children: [
    {
      title: t("ListOfExportedReports"),
      route_name: "exported-reports",
      allowedPermission: 0,
    },
    // {
    //   title: t("ReportDownloadHistory"),
    //   route_name: "report-download-history",
    //   allowedPermission: 0,
    // },
    // {
    //   title: t("ReportPresets"),
    //   route_name: "report-presets",
    //   allowedPermission: 0,
    // },
  ],
};

const Users: NavLink = {
  title: t("Users"),
  route_name: "users",
  icon: UserManagmentVue,
  allowedPermission: Permission.UserManagement,
  children: [
    {
      title: t("AllUsers"),
      route_name: "all-users",
      allowedPermission: Permission.UserManagement,
    },
    {
      title: t("StudentUsers"),
      route_name: "student-users",
      allowedPermission: Permission.UserManagement,
    },
    {
      title: t("ParentUsers"),
      route_name: "parent-users",
      allowedPermission: Permission.UserManagement,
    },
    {
      title: t("teacherUsers"),
      route_name: "teacher-users",
      allowedPermission: Permission.UserManagement,
    },
  ],
};

const Settings: NavLink = {
  title: t("Settings"),
  route_name: "settings",
  icon: SettingsVue,
  allowedPermission: 0,
  children: [
    {
      title: t("SettingsPanel"),
      route_name: "settings-panel",
      allowedPermission: 0,
    },
    // {
    //   title: t("SystemTimeDateSettings"),
    //   route_name: "system-time-date-settings",
    //   allowedPermission: 0,
    // },
    {
      title: t("ContentLangSettings"),
      route_name: "content-lang-settings",
      allowedPermission: 0,
    },
    {
      title: t("SemesterChangeSettings"),
      route_name: "semester-change-settings",
      allowedPermission: 0,
    },
    {
      title: t("LeaderBoardResetSettings"),
      route_name: "leader-board-reset-settings",
      allowedPermission: 0,
    },
    {
      title: t("AdminsManagement"),
      route_name: "admins-management-list",
      allowedPermission: 0,
    },
    {
      title: t("image-management"),
      route_name: "image-management",
      allowedPermission: 0,
    },
    {
      title: t("AppNotificationControls"),
      route_name: "app-notification-controls",
      allowedPermission: 0,
    },
    {
      title: t("AdminNotificationControls"),
      route_name: "admin-notification-controls",
      allowedPermission: 0,
    },
    {
      title: t("SoftDeleteManagement"),
      route_name: "soft-delete-management",
      allowedPermission: 0,
    },
  ],
};

const SubjectsAndGrade: NavLink = {
  title: t("SubjectsAndGrade"),
  route_name: "subjects-and-grade",
  icon: SubjectAndGradesVue,
  allowedPermission: 0,
  children: [
    {
      title: t("ListOfSubjects"),
      route_name: "ListOfSubjects",
      allowedPermission: 0,
    },
    {
      title: t("ListOfGrades"),
      route_name: "ListOfGrades",
      allowedPermission: 0,
    },
    {
      title: t("ListOfSchools"),
      route_name: "ListOfSchools",
      allowedPermission: 0,
    },
    {
      title: t("list-of-cities"),
      route_name: "ListOfCities",
      allowedPermission: 0,
    },
    {
      title: t("list-of-countries"),
      route_name: "ListOfCountries",
      allowedPermission: 0,
    },
  ],
};

const SupportFAQ: NavLink = {
  title: t("SupportFAQ"),
  route_name: "support-faq",
  icon: SupportFAQVue,
  allowedPermission: 0,
  children: [
    {
      title: t("FAQ"),
      route_name: "FAQ",
      allowedPermission: 0,
    },
    {
      title: t("BUGReportList"),
      route_name: "BUGReportList",
      allowedPermission: 0,
    },
    {
      title: t("TechnicalIssuesList"),
      route_name: "TechnicalIssuesList",
      allowedPermission: 0,
    },
    {
      title: t("SupportIssuesList"),
      route_name: "SupportIssuesList",
      allowedPermission: 0,
    },
    {
      title: t("SubmittedQuestions"),
      route_name: "SubmittedQuestions",
      allowedPermission: 0,
    },
  ],
};

export default [
  Home,
  Dashboard,
  Users,
  SubjectsAndGrade,
  LeaderBoard,
  QuizManagement,
  SupportFAQ,
  BadgesList,
  Reports,
  Settings,
  Logs,
] as NavLink[];
