import { useAuth } from '@/modules/auth/stores/auth'

export default function () {
  const auth = useAuth() // GET AUTH STORE: to get user permissions

  const has = (PERMISSION: number) => {
    if (!PERMISSION) {
      // if no permission required, return true
      return true
    }
    const permissions = auth.user?.permissions || 0

    if ((permissions & PERMISSION) === PERMISSION) {
      return true
    }

    return false
  }

  return {
    has,
  }
}
