<template>
  <section
    v-bind="$attrs"
    v-if="current_page && last_page"
    class="flex flex-wrap  items-center justify-center gap-5 lg:justify-between my-5"
  >
    <div class="flex items-center justify-start gap-2 whitespace-nowrap">
      <label for="selectPage" class="text-sm text-dark600">{{
        $t("page")
      }}</label>
      <select
        id="selectPage"
        @change="(e) => setPage(e)"
        :value="current_page"
        class="!appearance-none !bg-none text-dark600 border border-[#707070] !px-4 rounded-lg flex justify-center items-center text-center text-sm"
      >
        <option v-for="page in last_page" :value="page">{{ page }}</option>
      </select>
      <p class="text-sm text-dark600">
        {{ $t("of-last_page-pages", { last_page }) }}
      </p>
    </div>
    <div class="inline-flex space-x-1 text-sm font-medium" :class="class">
      <button
        @click="() => setPage(1)"
        :disabled="current_page === 1"
        class="inline-flex items-center justify-center rounded-lg w-11 h-11 hover:bg-primary/40 text-dark600 bg-tertiary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="20"
          viewBox="0 0 16 20"
        >
          <text
            id="_"
            data-name="&gt;&gt;"
            transform="translate(8 5) rotate(180)"
            fill="#5a5a5a"
            font-size="14"
            font-family="Poppins-Regular, Poppins"
          >
            <tspan x="-7.546" y="0">&gt;&gt;</tspan>
          </text>
        </svg>
      </button>
      <button
        @click="() => setPage(current_page - 1)"
        :disabled="current_page === 1"
        class="inline-flex items-center justify-center rounded-lg w-11 h-11 hover:bg-primary/40 text-dark600 bg-tertiary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="20"
          viewBox="0 0 8 20"
        >
          <text
            id="_"
            data-name="&gt;"
            transform="translate(4 5) rotate(180)"
            fill="#5a5a5a"
            font-size="14"
            font-family="Poppins-Regular, Poppins"
          >
            <tspan x="-3.773" y="0">&gt;</tspan>
          </text>
        </svg>
      </button>
      <template
        v-for="(page, index) in pagination(current_page, last_page)"
        :key="index"
      >
        <button
          type="button"
          :class="
            page === current_page
              ? twFocusClass() && ' bg-primary text-white'
              : 'hover:bg-primary/40  text-dark600 bg-tertiary'
          "
          class="inline-flex items-center justify-center rounded-lg w-11 h-11"
          @click="() => setPage(page)"
        >
          {{ page }}
        </button>
      </template>
      <button
        @click="() => setPage(current_page + 1)"
        :disabled="current_page === last_page"
        class="inline-flex items-center justify-center rounded-lg w-11 h-11 hover:bg-primary/40 text-dark600 bg-tertiary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="20"
          viewBox="0 0 8 20"
        >
          <text
            id="_"
            data-name="&gt;"
            transform="translate(4 15)"
            fill="#5a5a5a"
            font-size="14"
            font-family="Poppins-Regular, Poppins"
          >
            <tspan x="-3.773" y="0">&gt;</tspan>
          </text>
        </svg>
      </button>
      <button
        @click="() => setPage(last_page)"
        :disabled="current_page === last_page"
        class="inline-flex items-center justify-center rounded-lg w-11 h-11 hover:bg-primary/40 text-dark600 bg-tertiary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="20"
          viewBox="0 0 16 20"
        >
          <text
            id="_"
            data-name="&gt;&gt;"
            transform="translate(8 15)"
            fill="#5a5a5a"
            font-size="14"
            font-family="Poppins-Regular, Poppins"
          >
            <tspan x="-7.546" y="0">&gt;&gt;</tspan>
          </text>
        </svg>
      </button>
    </div>
  </section>
</template>
<script lang="ts" setup>
import twFocusClass from "@/core/utils/twFocusClass";
import { useRouter } from "vue-router";

const router = useRouter();

defineProps<{
  class?: string;
  last_page: number;
  current_page: number;
}>();


function setPage(page: number | Event) {
  if (typeof page === "object") {
    page = parseInt((page.target as HTMLInputElement).value);
  }
  const prev_query = router.currentRoute.value.query;
  const query = Object.assign({}, prev_query);
  query["PageNumber"] = page.toString();
  router.push({ query });
}

function pagination(currentPage: number, pageCount: number) {
  let delta = 2,
    left = currentPage - delta,
    right = currentPage + delta + 1,
    result = [];

  result = Array.from(
    {
      length: pageCount,
    },
    (v, k) => k + 1
  ).filter((i) => i && i >= left && i < right);

  return result;
}
</script>
