import { t } from '@/core/i18n'


const API_URL = '/v1.0/management/Settings/Stage-Limits'

const MODULE_TITLE = t('app-game-controls')

const NAME = 'StageLimits'

const tableHeader: LabelVal[] = [
  {
    label: t('stage'),
    value: 'Stage',
  },
  {
    label: t('multiplier'),
    value: 'Multiplier',
  },
  {
    label: t('guest-access'),
    value: 'GuestAccess',
  },
  {
    label: t('q-pool-min'),
    value: 'QPoolMin',
  },
  {
    label: t('q-pool-max'),
    value: 'QPoolMax',
  },
]


export default {
  API_URL,
  tableHeader,
  NAME,
  MODULE_TITLE,
}
