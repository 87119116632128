import { t } from '@/core/i18n'

const API_URL = '/v1.0/management/Schools'

const MODULE_TITLE = t('list-of-schools')

const NAME = 'schools'

const settings = {
  hasView: false,
  hasExport: true,
  hasBlock: false,
  hasDelete: true,
  hasNotify: false,
  hasFilter: true,
  hasSearch: true,
  hasEdit: true,
  rowsOptions: {
    hasView: true,
    hasBlock: false,
    hasDelete: false,
    hasEdit: true,
  },
}



const tableHeader: LabelVal[] = [
  {
    label: 'id',
    value: 'id',
  },
  {
    label: t('school-name-arabic'),
    value: 'nameArabic',
  },
  {
    label: t("school-name-english"),
    value: 'name',
  },
  {
    label: t('number-of-students'),
    value: 'noOfStudents',
  },
  {
    label: t('created-on'),
    value: 'createdAt',
  },
  {
    label: t('action'),
    value: 'Action',
  },
]

export default {
  API_URL,
  settings,
  tableHeader,
  NAME,
  MODULE_TITLE,
}
