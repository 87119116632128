import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Database, getDatabase } from "firebase/database";
import { getAuth, type Auth } from "firebase/auth";
import config from "../config";

// Initialize Firebase
const firebaseApp = initializeApp(config.firebaseConfig);

const firebaseAuth: Auth = getAuth(firebaseApp);
const db: Database = getDatabase(firebaseApp);

firebaseAuth.useDeviceLanguage();
getAnalytics(firebaseApp);

export { firebaseAuth, db };

export default firebaseApp;
